body {
  padding-top: 1em;
}

.grid td,
.grid th {
  width: 150px;
  height: 150px;
  text-align: center;
  veritcal-align: center;
}

.logo {
  width: 75px;
  height: 75px;
}

.guess-select {
  max-width: 450px;
  margin-bottom: 1em;
}

.year-select select {
  font-size: 1em;
  margin-left: 0.5em;
}

.unqualified-select {
  margin-top: 0.5em;
}

button.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.instructions-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.instructions-modal-container {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  min-width: 300px;
  background-color: #fff;
  padding: 1em;
  border: 1px solid black;
  -webkit-box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.15);
}

.instructions-container.hidden {
  display: none !important;
}

table {
  margin-top: 2em;
  border-collapse: collapse;
}
.b-all {
  border: 1px solid #ccc;
}
.bt {
  border-top: 1px solid #ccc;
}
.bl {
  border-left: 1px solid #ccc;
}

footer {
  margin-top: 3em;
}
